import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';

import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';
import TabMenu from '../Tab';

import HRVendorGold from './assets/hr-vendor-gold.png';
import HrVendorSilver from './assets/hr-vendor-silver.png';
import BrandLaureate from './assets/brand-laureate.png';
import LifeAtWork from './assets/lawa.png';

import Shopee from './assets/shopee.svg';
import Lazada from './assets/lazada.svg';
import BilaBilaMart from './assets/bila-bila-mart.svg';
import MyDin from './assets/mydin.svg';
import EatCakeToday from './assets/eatcaketoday.svg';
import Loreal from './assets/loreal.svg';
import AIAInsurance from './assets/aia.svg';
import Foodpanda from './assets/foodpanda.svg';

import Secured from './assets/secured.svg';
import Trophy from './assets/trophy.svg';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';
import LoginSignupButtons from './LoginSignupButtons';

const Container = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
`;
const Content = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? '0rem 0rem 4rem 0rem' : '1rem')};
  gap: 1rem;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
`;
const StyledCard = styled(Card)`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  flex: ${({ grow }) => grow && 1};
  position: ${({ sticky }) => sticky && 'sticky'};
  z-index: ${({ sticky }) => sticky && 1};
  bottom: calc(3.875rem - 1px);
`;
const SocialSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const SocialSection = styled.div`
  background-color: ${colors.warningPurple};
  padding: 1rem;
  border-radius: 8px;
`;
const SocialTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const SocialTitleIcon = styled.img`
  height: 35px;
`;
const SocialTitle = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: 700;
`;
const PurpleHighlight = styled.span`
  color: ${colors.purple};
`;
const SocialContent = styled.div`
  padding: 1rem 0;
`;
const SocialImage = styled.img`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '60%')};
  padding: 1rem 0;
  margin: 0 auto;
  display: block;
  filter: ${({ noFilter }) => !noFilter && 'grayscale(100%)'};
`;
const SocialText = styled.div`
  padding-top: 0.5rem;
  font-size: ${fontSize.medium};
  text-align: center;
`;

const Home = () => {
  const desktopLayout = useSelector(state => state.layout.desktopLayout);

  const history = useHistory();

  return (
    <Container>
      <StickyHeader>
        <FixedHeaderAppBar
          title={desktopLayout ? 'Home' : null}
          showLogo={!desktopLayout}
          isMobile={!desktopLayout}
          isSwitchJobviewShowing
        />
      </StickyHeader>
      <Content isMobile={!desktopLayout}>
        <StyledCard
          elevation={desktopLayout ? 2 : 0}
          sticky={!desktopLayout}
          maxWidth="400"
        >
          <CardContent>
            <LoginSignupButtons />
          </CardContent>
        </StyledCard>
        <StyledCard elevation={desktopLayout ? 2 : 0} grow={!desktopLayout}>
          <CardContent>
            <SocialSectionWrapper>
              <SocialSection>
                <SocialTitleRow>
                  <SocialTitleIcon src={Trophy} alt="Awards" />
                  <SocialTitle>
                    The proof is in the{' '}
                    <PurpleHighlight>Awards</PurpleHighlight>
                  </SocialTitle>
                </SocialTitleRow>
                <SocialContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <SocialImage
                        src={HRVendorGold}
                        alt="HR Vendor Award"
                        noFilter
                      />
                      <SocialText>Best Recruitment Portal</SocialText>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <SocialImage
                        src={HrVendorSilver}
                        alt="HR Vendor Award"
                        noFilter
                      />
                      <SocialText>
                        Best Gig Workforce
                        <br />
                        Management Platforms
                      </SocialText>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <SocialImage
                        src={BrandLaureate}
                        alt="Brand Laureate"
                        noFilter
                      />
                      <SocialText>
                        Thriving Tech, HRTech
                        <br />
                        On-Demand Workforce
                        <br />
                        Management Solutions
                      </SocialText>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <SocialImage
                        src={LifeAtWork}
                        alt="TalentCorp: Life At Work"
                        noFilter
                      />
                      <SocialText>
                        Talent Sustainability
                        <br />
                        (2nd Runner Up)
                      </SocialText>
                    </Grid>
                  </Grid>
                </SocialContent>
              </SocialSection>
              <SocialSection>
                <SocialTitleRow>
                  <SocialTitleIcon src={Secured} alt="Trusted" />
                  <SocialTitle>
                    Trusted by <PurpleHighlight>10,000+</PurpleHighlight>{' '}
                    businesses
                  </SocialTitle>
                </SocialTitleRow>
                <SocialContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                      <SocialImage src={Shopee} alt="Shopee" fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SocialImage src={Lazada} alt="Lazada" fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SocialImage
                        src={BilaBilaMart}
                        alt="Bila Bila Mart"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SocialImage src={MyDin} alt="MyDin" fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SocialImage
                        src={EatCakeToday}
                        alt="EatCakeToday"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SocialImage src={Loreal} alt="l'Oreal" fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SocialImage src={AIAInsurance} alt="AIA" fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SocialImage src={Foodpanda} alt="Foodpanda" fullWidth />
                    </Grid>
                  </Grid>
                </SocialContent>
              </SocialSection>
            </SocialSectionWrapper>
          </CardContent>
        </StyledCard>
      </Content>
      {!desktopLayout && <TabMenu history={history} mobile />}
    </Container>
  );
};

export default Home;
