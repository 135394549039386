import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Avatar from '@mui/material/Avatar';
import styled from 'styled-components/macro';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Drawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import { getCookie } from '../libraries/authentication';

// icons
import Close from '@mui/icons-material/Close';
import FactCheck from '@mui/icons-material/FactCheck';
import HomeIcon from '@mui/icons-material/Home';
import MyJobsIcon from '@mui/icons-material/Work';
import NotificationIcon from '@mui/icons-material/Notifications';
import AccountIcon from '@mui/icons-material/AccountCircle';

import colors from '../style/colors';
import fontSize from '../style/fontSize';

const StyledBadge = styled(Badge)`
  span:last-child {
    background-color: ${colors.red} !important;
    height: 8px !important;
    width: 8px !important;
    left: 13px;
    top: 0px;
  }
`;
const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 100 !important;
  border-top: 1px solid ${colors.greyLight} !important;
  height: 61px !important;
`;
const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  min-width: 0px !important;
  .MuiBottomNavigationAction-label {
    font-size: ${fontSize.regular} !important;
    margin-top: 4px;
  }
`;
const StyledAvatar = styled(Avatar)`
  width: 26px !important;
  height: 26px !important;
`;
const DrawerContent = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.25rem;
`;
const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;
const DrawerTitle = styled.div`
  font-size: ${fontSize.large};
`;
const DrawerBody = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
`;
const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;
const JobAction = styled(CardActionArea)`
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const JobActionTitle = styled.div`
  font-size: ${fontSize.large};
`;

const Tab = ({ history, mobile, userAvatar, unreadCount }) => {
  const [showMyJobsMenu, setShowMyJobsMenu] = useState(false);

  const getJobsUrl = () => {
    if (history.location.pathname === '/jobs') {
      return '/jobs';
    } else if (history.location.pathname === '/hiring_posts') {
      return '/hiring_posts';
    }
  };

  // TODO: Cordova is being Cordova and doesn't push to '/' so need to use replace.
  const handlePushHome = () => {
    history.replace('/');
  };

  const handleClickMyJobs = () => {
    setShowMyJobsMenu(false);
    if (getCookie('isShowingGoGetRecruit') === 'true') {
      history.push('/hiring_posts');
    } else {
      history.push('/jobs');
    }
  };

  const handleClickAttendance = () => {
    setShowMyJobsMenu(false);
    history.push('/attendance/dashboard');
  };

  const handleMyJobsMenu = () => {
    setShowMyJobsMenu(!showMyJobsMenu);
  };

  return (
    <>
      <Drawer open={showMyJobsMenu} onClose={handleMyJobsMenu} anchor="bottom">
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Choose</DrawerTitle>
            <IconButton size="small" onClick={handleMyJobsMenu}>
              <Close color="inherit" />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <ButtonColumn>
              <Card>
                <JobAction onClick={handleClickMyJobs}>
                  <MyJobsIcon />
                </JobAction>
              </Card>
              <JobActionTitle>My Jobs</JobActionTitle>
            </ButtonColumn>
            <ButtonColumn>
              <Card>
                <JobAction onClick={handleClickAttendance}>
                  <FactCheck />
                </JobAction>
              </Card>
              <JobActionTitle>Attendance</JobActionTitle>
            </ButtonColumn>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {mobile && (
        <StyledBottomNavigation value={history.location.pathname} showLabels>
          <StyledBottomNavigationAction
            label="Create Job"
            value="/"
            icon={<HomeIcon />}
            onClick={handlePushHome}
            // component={Link}
            // to="/"
          />
          <StyledBottomNavigationAction
            label="My Jobs"
            value={getJobsUrl()}
            icon={<MyJobsIcon />}
            onClick={handleMyJobsMenu}
          />
          <StyledBottomNavigationAction
            label="Notification"
            value="/notifications"
            icon={
              unreadCount !== 0 ? (
                <StyledBadge variant="dot" color="primary">
                  <NotificationIcon />
                </StyledBadge>
              ) : (
                <NotificationIcon />
              )
            }
            component={Link}
            to="/notifications"
          />
          <StyledBottomNavigationAction
            id="my-account-mb"
            label="Profile"
            value="/profile"
            icon={
              userAvatar ? (
                <StyledAvatar alt="image" src={userAvatar} />
              ) : (
                <AccountIcon />
              )
            }
            component={Link}
            to="/profile"
          />
        </StyledBottomNavigation>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    unreadCount: state.notification && state.notification.unreadCount,
    userAvatar: state.user && state.user.profile && state.user.profile.avatar
  };
};

export default connect(mapStateToProps)(Tab);
