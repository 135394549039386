import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import FixedHeaderAppBar from '../ui/FixedHeaderAppBar';

import GogetLogoBlack from '../assets/black_logo.png';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

import { logout } from '../../libraries/authentication';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Card = styled.div`
  color: ${colors.greyDark};
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;
const Img = styled.img`
  width: 100px;
  margin: 0 auto;
  display: block;
`;
const Message = styled.div`
  font-size: 1.7rem;
  color: ${colors.primary};
  margin-bottom: 4rem;
`;
const FinePrint = styled.div`
  font-size: ${fontSize.large};
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
const StyledButton = styled(Button)`
  width: calc(100% - 8rem);
  color: ${colors.white} !important;
`;
const Logout = styled.span`
  color: ${colors.primary};
  font-weight: 600;
  cursor: pointer;
`;

const ErrorBoundaryPage = () => {
  const desktopLayout = useSelector(state => state.layout?.desktopLayout);

  return (
    <Container>
      <div>
        <FixedHeaderAppBar showWallet isMobile={!desktopLayout} />
      </div>
      <LinearProgress />
      <Body>
        <Card>
          <Img src={GogetLogoBlack} />
          <Message>Something Went Wrong</Message>
          <StyledButton variant="contained" color="primary" href="/">
            Go back
          </StyledButton>
          <FinePrint>
            If that doesn&apos;t work, try{' '}
            <Logout onClick={logout}>signing out </Logout>and back in.
          </FinePrint>
        </Card>
      </Body>
    </Container>
  );
};

export default ErrorBoundaryPage;
