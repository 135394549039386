import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components/macro';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ContactSupportIcon from '@mui/icons-material/Mail';
import HelpCenterIcon from '@mui/icons-material/Help';
import HowToTutorial from '@mui/icons-material/PlayCircleFilled';
import HaveBusinessIcon from '@mui/icons-material/BusinessCenter';
import WhatsApp from '@mui/icons-material/WhatsApp';

import { getAuthToken } from '../../libraries/authentication';

import colors from '../../style/colors';
import fontSize from '../../style/fontSize';

import SchedulerImage from '../assets/schedulerImage.jpg';

const Link = styled.a``;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  &.scheduler {
    margin-bottom: 0;
    cursor: pointer;
  }
  > .icon {
    width: 25px !important;
    height: 25px !important;
    color: ${colors.black} !important;
  }
`;
const Item = styled.div`
  margin-left: 1rem;
  font-size: ${fontSize.large};
  color: ${colors.black};
`;
const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  right: 0 !important;
`;
const DialogBody = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
const TextBody = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.black};
  text-align: center;
`;
const Name = styled.div`
  text-align: center;
  color: ${colors.primary};
  font-size: ${fontSize.large};
  margin-bottom: 1rem;
`;
const StyledDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 8px !important;
    max-width: 400px;
  }
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
`;
const StyledButton = styled(Button)`
  position: relative;
  bottom: 20px !important;
  width: calc(100% - 32px) !important;
  margin: auto !important;
  color: ${colors.white} !important;
`;
const Black = styled.span`
  color: ${colors.black};
`;

class HelpSupport extends Component {
  state = {
    isSchedulerModalOpen: false
  };

  handleLinkScheduler = () => {
    window.open('https://wa.link/fhu2s6');
  };

  handleSchedulerModal = () => {
    this.setState(prevState => {
      return { isSchedulerModalOpen: !prevState.isSchedulerModalOpen };
    });
  };

  render() {
    const { isSchedulerModalOpen } = this.state;
    const { name, isRecruitJob } = this.props;

    return (
      <>
        <Link
          href="https://gogetmy.zendesk.com/hc/en-us"
          rel="noopener noreferrer"
          target="_blank"
        >
          <ItemWrapper>
            <HelpCenterIcon className="icon" />
            <Item>Help Center</Item>
          </ItemWrapper>
        </Link>
        <Link
          href="https://gogetmy.zendesk.com/hc/en-us/requests/new"
          rel="noopener noreferrer"
          target="_blank"
        >
          <ItemWrapper>
            <ContactSupportIcon className="icon" />
            <Item>Email Support</Item>
          </ItemWrapper>
        </Link>
        <Link
          href="https://wa.link/fhu2s6"
          rel="noopener noreferrer"
          target="_blank"
        >
          <ItemWrapper>
            <WhatsApp className="icon" />
            <Item>Contact Us</Item>
          </ItemWrapper>
        </Link>
        <Link
          href="https://gogetmy.zendesk.com/hc/en-us/sections/360005628994-Video-Tutorials"
          rel="noopener noreferrer"
          target="_blank"
        >
          <ItemWrapper>
            <HowToTutorial className="icon" />
            <Item>How-To Tutorials</Item>
          </ItemWrapper>
        </Link>
        {isRecruitJob && getAuthToken() && (
          <ItemWrapper
            onClick={this.handleSchedulerModal}
            className="scheduler"
          >
            <HaveBusinessIcon className="icon" />
            <Item>Book a meeting on GoGet Recruit.</Item>
          </ItemWrapper>
        )}
        <StyledDialog
          onClose={this.handleSchedulerModal}
          open={isSchedulerModalOpen}
        >
          <DialogBody>
            <Name>
              <Black>Hey</Black> {name}
            </Name>
            <TextBody>
              Schedule a 15 mins call with our team to guide you through{' '}
              {isRecruitJob ? 'GoGet Recruit.' : 'the platform!'}
            </TextBody>
          </DialogBody>
          <ImageWrapper>
            <Image src={SchedulerImage} alt="img" />
          </ImageWrapper>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={this.handleLinkScheduler}
          >
            CONTACT US
          </StyledButton>
          <StyledIconButton>
            <CloseIcon size="small" onClick={this.handleSchedulerModal} />
          </StyledIconButton>
        </StyledDialog>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    name: state.user && state.user.profile && state.user.profile.name,
    isBusinessAccount:
      state.user && state.user.profile && state.user.profile.is_business_account
  };
};

export default connect(mapStateToProps)(HelpSupport);
