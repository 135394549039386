import { lazy } from 'react';
import { lazyRetry } from '../../libraries/lazyRetry';

const Profile = lazy(() =>
  lazyRetry(() => import('../../components/profile/ProfileV2'))
);
const EditProfile = lazy(() =>
  lazyRetry(() => import('../../components/profile/EditProfile'))
);
const Reviews = lazy(() =>
  lazyRetry(() => import('../../components/profile/Reviews'))
);
const Settings = lazy(() =>
  lazyRetry(() => import('../../components/profile/Settings'))
);
const Privacy = lazy(() =>
  lazyRetry(() => import('../../components/profile/Privacy'))
);
const Referral = lazy(() =>
  lazyRetry(() => import('../../components/referral/Referral'))
);
const MyJobs = lazy(() =>
  lazyRetry(() => import('../../components/my_jobs/MyJobs'))
);
const EditJob = lazy(() =>
  lazyRetry(() => import('../../components/job_edit/EditJob'))
);
const JobShow = lazy(() =>
  lazyRetry(() => import('../../components/job_show/Show'))
);
const Chat = lazy(() => lazyRetry(() => import('../../components/chat/Chat')));
const CreditReceipt = lazy(() =>
  lazyRetry(() => import('../../components/credits/Receipt'))
);
const Receipt = lazy(() =>
  lazyRetry(() => import('../../components/receipt/Receipt'))
);
const Report = lazy(() =>
  lazyRetry(() => import('../../components/job_show/Report'))
);
const Templates = lazy(() =>
  lazyRetry(() => import('../../components/templates/Templates'))
);
const Rewards = lazy(() =>
  lazyRetry(() => import('../../components/rewards/Rewards'))
);
const HiringPostsJobs = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/Jobs'))
);
const ManageBulkOrders = lazy(() =>
  lazyRetry(() => import('../../components/manageBulkOrder/ManageBulkOrders'))
);
const ChangeAccount = lazy(() =>
  lazyRetry(() => import('../../components/profile/ChangeAccount'))
);
const DeleteAccount = lazy(() =>
  lazyRetry(() => import('../../components/profile/DeleteAccount'))
);
const BlockedList = lazy(() =>
  lazyRetry(() => import('../../components/profile/BlockedList'))
);
const UpdatePassword = lazy(() =>
  lazyRetry(() => import('../../components/profile/UpdatePassword'))
);
const HelpSupportPage = lazy(() =>
  lazyRetry(() => import('../../components/profile/HelpSupportPage'))
);
const FavouriteGoGetters = lazy(() =>
  lazyRetry(() => import('../../components/myGoGetters/MyGoGetters'))
);
const Announcement = lazy(() =>
  lazyRetry(() => import('../../components/announcement/Announcement'))
);
const Notification = lazy(() =>
  lazyRetry(() => import('../../components/notification/Notification'))
);
const GgInspector = lazy(() =>
  lazyRetry(() => import('../../components/ggInspector/Dashboard'))
);
const GoGetterProfile = lazy(() =>
  lazyRetry(() => import('../../components/myGoGetters/GoGetterProfile'))
);
const HiringPostEdit = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/HiringPostEdit'))
);
const HiringPostsJobDetails = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/Details'))
);
const HiringPostsReceipt = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/Receipt'))
);
const ListofApplicants = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/ListofApplicants'))
);
const ApplicantDetails = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/ApplicantDetails'))
);
const ApplicantsReview = lazy(() =>
  lazyRetry(() => import('../../components/hiringPost/ApplicantsReview'))
);
const AttendanceDashboard = lazy(() =>
  lazyRetry(() =>
    import('../../components/proofOfAttendance/AttendanceDashboard')
  )
);
const GuidelineSettings = lazy(() =>
  lazyRetry(() =>
    import('../../components/proofOfAttendance/settings/Guideline')
  )
);

export const privateRouters = [
  {
    path: '/manage_bulk_orders/:bulkId',
    component: ManageBulkOrders
  },
  {
    path: '/manage_bulk_orders',
    component: ManageBulkOrders
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/change_account',
    component: ChangeAccount
  },
  {
    path: '/edit_profile',
    component: EditProfile
  },
  {
    path: '/reviews',
    component: Reviews
  },
  {
    path: '/change_password',
    component: UpdatePassword
  },
  {
    path: '/settings',
    component: Settings
  },
  {
    path: '/settings/guideline',
    component: GuidelineSettings
  },
  {
    path: '/privacy',
    component: Privacy
  },
  {
    path: '/referral',
    component: Referral
  },
  {
    path: '/help_support',
    component: HelpSupportPage
  },
  {
    path: '/favourites',
    component: FavouriteGoGetters
  },
  {
    path: '/announcements/:id',
    component: Notification,
    mobileComponent: Announcement
  },
  {
    path: '/notifications',
    component: Notification
  },
  {
    path: '/jobs',
    component: MyJobs
  },
  {
    path: '/jobs/:id/edit',
    component: EditJob
  },
  {
    path: '/jobs/:id/messages',
    component: Chat
  },
  {
    path: '/jobs/:id/receipt',
    component: Receipt
  },
  {
    path: '/credits/:id/receipt',
    component: CreditReceipt
  },
  {
    path: '/jobs/:id/reports',
    component: Report
  },
  {
    path: '/templates',
    component: Templates
  },
  {
    path: '/rewards',
    component: Rewards
  },
  {
    path: '/gg_inspector',
    component: GgInspector
  },
  {
    path: '/gogetter/:gogetterId',
    component: GoGetterProfile
  },
  {
    path: '/jobs/:id',
    component: MyJobs,
    mobileComponent: JobShow
  },
  {
    path: '/privacy/delete_account',
    component: DeleteAccount
  },
  {
    path: '/privacy/blocked_list',
    component: BlockedList
  },
  {
    path: '/hiring_posts',
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id',
    mobileComponent: HiringPostsJobDetails,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/edit',
    component: HiringPostEdit
  },
  {
    path: '/hiring_posts/:id/applicants',
    mobileComponent: ListofApplicants,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/applicants/:applicantId',
    mobileComponent: ApplicantDetails,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/applicants/:applicantId/reviews',
    mobileComponent: ApplicantsReview,
    component: HiringPostsJobs
  },
  {
    path: '/hiring_posts/:id/receipt',
    component: HiringPostsReceipt
  },
  {
    path: '/attendance/dashboard',
    component: AttendanceDashboard
  }
];
