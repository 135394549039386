import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import ImageSlider from '../../ui/ImageSlider';

import AwardsBanner from './bannerAssets/gogetAwardsBanner.svg';
import ProjectBanner from './bannerAssets/gogetProjectBanner.svg';
import RecruitBanner from './bannerAssets/gogetRecruitBanner.svg';

import { getAuthToken } from '../../../libraries/authentication';
import { fetchFestivalPromos } from '../../../api/promos';

const BannerWrapper = styled.div`
  padding: 1rem 0;
  padding-bottom: 2rem;
`;

const MobileBanner = () => {
  const [images, setImages] = useState([
    {
      id: 1,
      src: AwardsBanner,
      alt: 'GoGet Awards 2024',
      linkTo:
        'https://www.linkedin.com/posts/goget-my_hrvotyawards-gigeconomy-recruitmentinnovation-activity-7264929506690252801-oimN?utm_source%3Dshare%26utm_medium%3Dmember_desktop&sa=D&source=editors&ust=1732275865680419&usg=AOvVaw2grQ3NaD3dXwFbW1Y5Ud4N'
    },
    {
      id: 2,
      src: ProjectBanner,
      alt: 'GoGet Projects',
      linkTo: 'https://goget.my/business/projects/'
    },
    {
      id: 3,
      src: RecruitBanner,
      alt: 'GoGet Recruit',
      linkTo: '/part_timer/recruit'
    }
  ]);

  const isBusinessAccount = useSelector(
    state => state.user?.profile?.is_business_account
  );

  const history = useHistory();

  const handleClickBanner = linkTo => {
    let userType;
    if (getAuthToken()) {
      userType = isBusinessAccount ? 'business_account' : 'free_account';
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'hero_banner',
      platform: 'pwa'
    });

    if (linkTo.includes('https')) {
      window.open(linkTo);
    } else {
      history.push(linkTo);
    }
  };

  useEffect(() => {
    const fetchBanners = async () => {
      const response = await fetchFestivalPromos();

      if (response?.isSuccess) {
        if (response.festivalBanners) {
          setImages(prevState => {
            return [
              {
                id: 0,
                src: response.festivalBanners?.desktop_img,
                alt: 'Festival banner',
                linkTo: null
              },
              ...prevState
            ];
          });
        }
      }
    };

    fetchBanners();
  }, []);

  return (
    <BannerWrapper>
      <ImageSlider images={images} handleClickBanner={handleClickBanner} />
    </BannerWrapper>
  );
};

export default MobileBanner;
