import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Card from '@mui/material/Card';

import AwardsBanner from './bannerAssets/gogetAwardsBanner.svg';
import ProjectBanner from './bannerAssets/gogetProjectBanner.svg';
import RecruitBanner from './bannerAssets/gogetRecruitBanner.svg';

import { fetchFestivalPromos } from '../../../api/promos';
import { getAuthToken } from '../../../libraries/authentication';

import fontSize from '../../../style/fontSize';

const StyledCard = styled(Card)`
  width: 350px;
  margin-bottom: 1rem;
`;
const BannerTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
  padding: 1rem;
`;
const BannerWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  max-height: 425px;
  overflow-y: auto;
  padding: 1rem;
  padding-top: 0;
`;
const Banner = styled.img`
  width: 100%;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'default')};
  transition: all 0.3s ease-in-out;

  :hover {
    filter: ${({ showPointer }) => (showPointer ? 'brightness(0.9)' : 'none')};
  }
`;

const DesktopBanner = () => {
  const [images, setImages] = useState([
    {
      id: 1,
      src: AwardsBanner,
      alt: 'GoGet Awards 2024',
      linkTo:
        'https://www.linkedin.com/posts/goget-my_hrvotyawards-gigeconomy-recruitmentinnovation-activity-7264929506690252801-oimN?utm_source%3Dshare%26utm_medium%3Dmember_desktop&sa=D&source=editors&ust=1732275865680419&usg=AOvVaw2grQ3NaD3dXwFbW1Y5Ud4N'
    },
    {
      id: 2,
      src: ProjectBanner,
      alt: 'GoGet Projects',
      linkTo: 'https://goget.my/business/projects/'
    },
    {
      id: 3,
      src: RecruitBanner,
      alt: 'GoGet Recruit',
      linkTo: '/part_timer/recruit'
    }
  ]);

  const isBusinessAccount = useSelector(
    state => state.user?.profile?.is_business_account
  );

  const history = useHistory();

  const handleClickBanner = linkTo => {
    let userType;
    if (getAuthToken()) {
      userType = isBusinessAccount ? 'business_account' : 'free_account';
    } else {
      userType = 'visitor';
    }
    window.analytics.track('initiate', {
      category: userType,
      label: 'hero_banner',
      platform: 'pwa'
    });

    if (linkTo.includes('https')) {
      window.open(linkTo);
    } else {
      history.push(linkTo);
    }
  };

  useEffect(() => {
    const fetchBanners = async () => {
      const response = await fetchFestivalPromos();

      if (response?.isSuccess) {
        if (response?.festivalBanners) {
          setImages(prevState => {
            return [
              {
                id: 0,
                src: response.festivalBanners?.desktop_img,
                alt: 'Festival banner',
                linkTo: null
              },
              ...prevState
            ];
          });
        }
      }
    };

    fetchBanners();
  }, []);

  return (
    <StyledCard>
      <BannerTitle>What's new</BannerTitle>
      <BannerWrap>
        {images.map(image => (
          <Banner
            key={image.id}
            src={image.src}
            alt={image.alt}
            showPointer={image.linkTo}
            onClick={
              image.linkTo ? () => handleClickBanner(image.linkTo) : () => {}
            }
          />
        ))}
      </BannerWrap>
    </StyledCard>
  );
};

export default DesktopBanner;
